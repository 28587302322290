var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "houses__wrapper screen__wrapper" },
        _vm._l(_vm.buyers, function(buyer) {
          return _c("Card", {
            key: buyer.id,
            attrs: { buyer: buyer },
            nativeOn: {
              click: function($event) {
                return _vm.info(buyer)
              }
            }
          })
        }),
        1
      ),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.loadObj } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }